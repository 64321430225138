import { autoinject, bindable, noView } from 'aurelia-framework';

const recaptchaCallbackName = 'setRecaptchaReady';
// tslint:disable-next-line: promise-must-complete
const ready = new Promise(resolve => window[recaptchaCallbackName] = resolve);

// https://developers.google.com/recaptcha/docs/display#explicit_render
const script = document.createElement('script');
script.src = `https://www.google.com/recaptcha/api.js?onload=${recaptchaCallbackName}&render=explicit`;
script.async = true;
script.defer = true;
document.head.appendChild(script);

declare var grecaptcha: any;

@noView()
@autoinject()
export class Recaptcha {
    @bindable verified;
    @bindable expired;
    @bindable theme = 'light';

    constructor(private readonly element: Element) {
    }

    async attached() {
        const response = await fetch('recaptcha.json');
        const sitekey = await response.text();

        await ready.then(() => grecaptcha.render(this.element, {
            'sitekey': sitekey,
            'theme': this.theme,
            'callback': this.verified,
            'expired-callback': this.expired
        }));
    }
}

import { observable } from 'aurelia-framework';
import { AureliaGridInstance, GridOption, Column } from 'aurelia-slickgrid';
import { IEntity, ResultPager } from 'plugins/data-models';

export abstract class GenericEntitySearch {
    @observable protected filter: string = '';

    protected gridOptions: GridOption;
    protected gridDataset = [];
    protected gridColumns: Column[] = [];
    protected aureliaGrid: AureliaGridInstance;
    protected pager: ResultPager;
    protected state: any;

    protected isAttached: boolean = false;
    protected isWaiting: boolean = false;

    protected keyDownCallback: (e: KeyboardEvent) => void;

    constructor() {
        this.defineGrid();

        this.keyDownCallback = this.keyDownForGridNavigate.bind(this);
    }

    abstract defineGrid();
    abstract editEntity(entity: IEntity): void;
    abstract newEntity(): void;
    abstract handleRowSelection(event, args);

    keyDownCallbackEnabled(enable) {
        if (enable) {
            window.addEventListener('keydown', this.keyDownCallback, false);
        } else {
            window.removeEventListener('keydown', this.keyDownCallback);
        }
    }

    attached() {
        this.keyDownCallbackEnabled(true);

        if (this.state) {
            this.filter = this.state.filter;
            this.gridDataset = this.state.results.map((entity: IEntity) => {
                return entity;
            });

            this.pager.results = this.state.results;
            if (this.state.pager) {
                this.pager.from = this.state.pager.from;
                this.pager.to = this.state.pager.to;
                this.pager.itemsPerPage = this.state.pager.itemsPerPage;
                this.pager.pageCount = this.state.pager.pageCount;
                this.pager.pageNumber = this.state.pager.pageNumber;
                this.pager.totalItems = this.state.pager.totalItems;
            }
        }
        this.isAttached = true;
        this.attachedDone();

    }

    attachedDone() {
        //
    }

    detached() {
        this.keyDownCallbackEnabled(false);
        this.isAttached = false;
    }

    filterChanged(newValue, oldValue) {
        if (this.isAttached && newValue != undefined && newValue != oldValue) {
            return this.pager.gotoFirst();
        }
    }

    aureliaGridCreated(aureliaGrid: AureliaGridInstance) {
        this.aureliaGrid = aureliaGrid;
        this.aureliaGrid.dataView.syncGridSelection(this.aureliaGrid.slickGrid, true);

        this.aureliaGrid.dataView.onRowsChanged.subscribe((e, args) => {
            if (this.state) {
                this.setSelectedRow(this.state.selectedRow);
            }
        });

        this.aureliaGrid.slickGrid.onKeyDown.subscribe(this.slickGridKeyDown);
    }

    slickGridKeyDown = (e) => {
        if (e.key == 'Enter') {
            const items: any[] = this.aureliaGrid.gridService.getSelectedRowsDataItem();
            if (items.length > 0) {
                this.editEntity(items[0]);
            }
        }
    }

    setSelectedRow(row: number) {
        if (this.aureliaGrid?.gridService) {
            this.aureliaGrid.gridService.setSelectedRows([]);
            this.aureliaGrid.gridStateService.resetRowSelectionWhenRequired();

            if (row >= 0) {
                this.aureliaGrid.gridService.setSelectedRow(row);
                this.aureliaGrid.slickGrid.scrollRowIntoView(row - 2);
            } else {
                this.aureliaGrid.gridService.setSelectedRow(0);
                this.aureliaGrid.slickGrid.scrollRowIntoView(0);
            }
        }
    }

    keyDownCancelUpDown(event) {
        // console.log('keyDownCancelUpDown', event.key);
        switch (event.key) {
            case 'ArrowDown':
            case 'ArrowUp':
                return false;
                break;
            default:
                return true;
        }
    }

    keyDownForGridNavigate(event) {
        switch (event.key) {
            case 'ArrowDown':
                this.selectNextRow();
                break;
            case 'ArrowUp':
                this.selectPreviousRow();
                break;
            case 'Enter':
                const items: any[] = this.aureliaGrid.gridService.getSelectedRowsDataItem();
                if (items.length > 0) {
                    this.editEntity(items[0]);
                }
            default:
                return true;
        }
    }

    selectNextRow() {
        let next = 0;
        const selectedIndexes: number[] = this.aureliaGrid.gridService.getSelectedRows();

        if (selectedIndexes.length > 0) {
            next = selectedIndexes[0] + 1;
            if (next >= this.gridDataset.length - 1) {
                next = this.gridDataset.length - 1;
            }
            this.setSelectedRow(next);
        }
    }

    selectPreviousRow() {
        let next = 0;
        const selectedIndexes: number[] = this.aureliaGrid.gridService.getSelectedRows();

        if (selectedIndexes.length > 0) {
            next = selectedIndexes[0] - 1;
        }
        this.setSelectedRow(next);
    }

    // stateChanged(newValue, oldValue) {
    //     console.log(newValue, oldValue);
    // }
}

export class TimeSlotValueConverter {
    public toView(value) {
        switch (value) {
            case 1:
                return '6AM';
            case 2:
                return 'Breakfast';
            case 3:
                return 'Lunch';
            case 4:
                return 'Supper';
            case 5:
                return 'Bed Time';
        }
    }
}

export class WeekDayValueConverter {
    public toView(value) {
        switch (value) {
            case 1:
                return 'Monday';
            case 2:
                return 'Tuesday';
            case 3:
                return 'Wednesday';
            case 4:
                return 'Thursday';
            case 5:
                return 'Friday';
            case 6:
                return 'Saturday';
            case 7:
                return 'Sunday';
        }
    }
}

export class DosageValueConverter {
    public toView(value) {
        if (value) {
            const split = value.toFixed(2).split('.');
            let fraction = '';

            switch (split[1]) {
                case '25':
                    fraction = '\u00BC'; // ¼
                    break;

                case '33':
                    fraction = '\u2153'; // ⅓
                    break;

                case '50':
                    fraction = '\u00BD'; // ½
                    break;

                case '66':
                    fraction = '\u2154'; // ⅔
                    break;

                case '75':
                    fraction = '\u00BE'; // ½
                    break;

                default:
                    break;
            }

            return (split[0] === '0' ? '' : split[0]) + fraction;
        }
        return value == '0' ? '' : value;
    }
}

export class StatusValueConverter {
    public toView(value) {
        switch (value) {
            case 'Packed':
                return 'Y';
            case 'Unpacked':
                return 'N';
        }
    }
}

export class PackedValueConverter {
    public toView(value) {
        return (value as []).filter((e: any) => e.status == 'Packed');
    }
}

export class UnPackedValueConverter {
    public toView(value) {
        return (value as []).filter((e: any) => e.status == 'Unpacked');
    }
}

export class PackedTotalValueConverter {
    public toView(value) {
        const packed: any = (value as []).filter((e: any) => e.status == 'Packed');
        return aSum((packed as []).map((m: any) => m.dosages));
    }
}

function aSum(args) {
    const total = [];
    for (let i = 0, l0 = args.length; i < l0; i++)
        for (let j = 0, arg = args[i], l1 = arg.length; j < l1; j++)
            total[j] = (total[j] == undefined ? 0 : total[j]) + arg[j];
    return total;
}

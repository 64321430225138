import { BindingEngine, bindingMode, Disposable, observable } from 'aurelia-binding';
import { inject, Optional } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-framework';
import { TaskQueue } from 'aurelia-task-queue';
import { Focus } from 'aurelia-templating-resources';

let searchInputId = 0;

@inject(Element, BindingEngine, TaskQueue, Optional.of(Focus))
export class SearchInput {

    id = searchInputId++;

    @bindable({ defaultBindingMode: bindingMode.twoWay }) value;
    @bindable title = '';
    @bindable placeholder = '';
    @bindable disabled = false;
    @bindable delay = 250;
    @bindable isWaiting = false;

    constructor(private readonly element: Element, private readonly focus: Focus) {
    }

}
import { bindable, bindingMode, customElement, useView } from 'aurelia-framework';
import { AcceptValidator } from './accept-validator';

@customElement('image-picker')
@useView('./image-picker.html')
export class ImagePicker {

  @bindable({ defaultBindingMode: bindingMode.twoWay }) files: File[] = [];
  @bindable accept = 'image/*';
  @bindable multiple = false;

  @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedFiles: FileList;

  input: HTMLInputElement;
  private acceptValidator: AcceptValidator = AcceptValidator.parse(this.accept);

  angle = 90;
  rotate() {


    var normalOrientation = this.angle % 180 == 0;
    var w = $('#parent').width();
    var h = $('#parent').height();
    var iw = $('#img').width();
    var ih = $('#img').height();
    var border = parseFloat($('#parent').css('border-top-width').replace(/px/, ''));
    var marginTop = border + ((Math.max(iw, ih) - Math.min(iw, ih)) / 2) - ((h - ih) / 2);
    $("#img").css({
      '-webkit-transform': 'rotate(' + this.angle + 'deg)',
      '-moz-transform': 'rotate(' + this.angle + 'deg)',
      '-o-transform': 'rotate(' + this.angle + 'deg)',
      '-ms-transform': 'rotate(' + this.angle + 'deg)',
      maxHeight: normalOrientation ? '100%' : w,
      maxWidth: normalOrientation ? '100%' : h,
      marginTop: normalOrientation || ih === iw ? 0 : ih > iw ? 0 - marginTop : marginTop
    });
    this.angle += 90;

  }

  acceptChanged() {
    this.acceptValidator = AcceptValidator.parse(this.accept);
  }

  add(files: FileList) {

    for (let i = 0; i < files.length; ++i) {

      if (this.files.length >= 1 && !this.multiple) {
        this.files.splice(0);
        this.clearSelection();
      }

      const file = files.item(i);
      const isValid = this.acceptValidator.isValid(file);
      if (isValid) {
        this.files.push(file);
      }
    }
  }

  remove(index) {
    this.files.splice(index, 1);
  }

  addSelectedFiles() {
    this.add(this.selectedFiles);
    this.selectedFiles = null;
  }

  selectedFilesChanged() {
    if (!this.selectedFiles) {
      this.clearSelection();
    }
  }

  private clearSelection() {
    this.input.type = '';
    this.input.type = 'file';
  }
}

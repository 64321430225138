import { FrameworkConfiguration, PLATFORM } from 'aurelia-framework';

export function configure(config: FrameworkConfiguration) {
  config.globalResources([
    PLATFORM.moduleName('./attributes/enhance'),
    PLATFORM.moduleName('./elements/api-result-feedback.html'),
    PLATFORM.moduleName('./attributes/btn-loading'),
    PLATFORM.moduleName('./attributes/debug'),
    PLATFORM.moduleName('./value-converters/image-path'),
    PLATFORM.moduleName('./value-converters/debug'),
    PLATFORM.moduleName('./value-converters/stringify'),
    PLATFORM.moduleName('./value-converters/data-uri'),

    PLATFORM.moduleName('./attributes/keydown'),
    PLATFORM.moduleName('./attributes/keypress'),
    PLATFORM.moduleName('./attributes/keyup'),

    PLATFORM.moduleName('./attributes/blob-src'),
    PLATFORM.moduleName('./attributes/file-drop-target'),
    PLATFORM.moduleName('./elements/file-picker/file-picker'),
    PLATFORM.moduleName('./elements/file-picker/image-files-picker'),
    PLATFORM.moduleName('./elements/file-picker/image-picker'),
    PLATFORM.moduleName('./elements/flatpickr/flatpickr'),

    PLATFORM.moduleName('./value-converters/chunk'),
    PLATFORM.moduleName('./value-converters/treatment-plan'),

    PLATFORM.moduleName('./elements/recaptcha'),
    PLATFORM.moduleName('./elements/search-input/search-input'),

    PLATFORM.moduleName('./elements/inactive-pharmacy.html')
  ]);
}

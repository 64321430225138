import { Container } from 'aurelia-framework';
import { IEntity, IRepository, IValidator } from 'plugins/data-models';
import { ToastrService } from 'services/toastr-service';

export abstract class GenericEntity {

    protected entity: IEntity;

    protected busyOk = false;
    protected busyApply = false;
    protected notification: any;
    protected cancelled = false;

    constructor(protected readonly repository: IRepository, protected readonly validator: IValidator) {
    }

    get isDirty(): boolean {
        return this.entity?.isDirty();
    }

    get isNew(): boolean {
        return this.entity?.isNew();
    }

    get canApply() {
        return (this.isDirty || this.isNew);
    }

    canMerge(): Promise<boolean> {
        return this.entity?.canMerge(this.validator);
    }

    async ok(escape = true) {
        if (this.isDirty || this.isNew) {
            if (await this.beforeMerge() && await this.canMerge()) {
                this.busyOk = true;
                if (await this.entity?.merge(this.repository)) {
                    this.success(`${this.displayName} is saved.`);
                    this.afterMerge();
                }
                this.busyOk = false;
            } else {
                this.warning('Please ensure all fields is valid.');
                const invalid = await this.entity.invalidFields(this.validator);
                console.log(invalid);
            }
        }

        if (this.isDirty || this.isNew) {
            //
        } else {
            if (escape) {
                this.escape();
            }
        }
    }

    cancel() {
        this.cancelled = true;
        this.entity?.cancel();
        this.escape();
    }

    async apply() {
        if (await this.beforeMerge() && await this.canMerge()) {
            this.busyApply = true;
            if (await this.beforeMerge() && await this.entity?.merge(this.repository)) {
                this.success(`${this.displayName} is saved.`);
                this.afterMerge();
            }
            this.busyApply = false;
        } else {
            this.warning('Please ensure all fields is valid.');
            const invalid = await this.entity.invalidFields(this.validator);
            console.log(invalid);
        }
    }

    beforeMerge(): Promise<boolean> {
        return Promise.resolve(true);
    }

    afterMerge(): void {
        //
    }

    warning(msg) {
        const toastr = Container.instance.get(ToastrService);
        toastr.clear(this.notification);
        this.notification = toastr.warning(msg);
    }

    info(msg) {
        const toastr = Container.instance.get(ToastrService);
        toastr.clear(this.notification);
        this.notification = toastr.info(msg);
    }

    success(msg) {
        const toastr = Container.instance.get(ToastrService);
        toastr.clear(this.notification);
        this.notification = toastr.success(msg);
    }

    async canDeactivate(): Promise<boolean> {
        if (this.cancelled) {
            return true;
        }

        if (this.isDirty || this.isNew) {
            this.warning('Please save or undo the changes.');
            const invalid = await this.entity.invalidFields(this.validator);
            console.log(invalid, this.entity.dirtyFields());
            return false;
        }
        return true;
    }

    abstract activate(params, routeConfig, navigationInstruction);
    abstract deactivate();
    abstract escape();
    abstract get displayName();
}

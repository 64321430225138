import { customAttribute } from 'aurelia-framework';

abstract class KeyUp {
    value: () => any;
    listener: (e: KeyboardEvent) => void;

    constructor(key: string) {
        this.listener = e => {
            // console.log('KeyUp', e);
            if (e.key === key) {
                return this.value();
            }
        };
    }

    attached() {
        window.addEventListener('keyup', this.listener);
    }

    detached() {
        window.removeEventListener('keyup', this.listener);
    }
}

@customAttribute('key-up-escape')
export class KeyUpEscape extends KeyUp {
    constructor() {
        super('Escape');
    }
}

@customAttribute('key-up-arrow-up')
export class KeyUpArrowUp extends KeyUp {
    constructor() {
        super('ArrowUp');
    }
}

@customAttribute('key-up-arrow-down')
export class KeyUpArrowDown extends KeyUp {
    constructor() {
        super('ArrowDown');
    }
}

@customAttribute('key-up-arrow-left')
export class KeyUpArrowLeft extends KeyUp {
    constructor() {
        super('ArrowLeft');
    }
}

@customAttribute('key-up-arrow-right')
export class KeyUpArrowRight extends KeyUp {
    constructor() {
        super('ArrowRight');
    }
}

import { EditorArgs, EditorValidator } from 'aurelia-slickgrid';

export const CustomDateValidator: EditorValidator = (value: any, args: EditorArgs) => {

    // console.log(value);
    const valid = isDate(value);

    if (value == '' || value == null || value === undefined || valid) {
        // valid
    } else {
        return { valid: false, msg: 'The value should be a date value' };
    }
    return { valid: true, msg: '' };
};

function isDate(s) {
    if (isNaN(s) && !isNaN(Date.parse(s)))
        return true;
    else return false;
}

import fingerprintjs from '@fingerprintjs/fingerprintjs';

import CryptoJS from 'crypto-js';

// resave finger print on start
fingerprintjs.load().then((load) => {
    load.get().then((fingerprint) => {
        localStorage.setItem('fingerprint', fingerprint.visitorId);
        getBrowserId();
        console.log(fingerprint.visitorId, getBrowserId());
    });
});

export function getBrowserId() {
    let browserId = localStorage.getItem('browserId');
    if (browserId === null) {
        localStorage.setItem('browserId', CryptoJS.lib.WordArray.random(10));
        browserId = localStorage.getItem('browserId');
    }
    return browserId;
}


export function getFingerprint() {
    const fingerprint = localStorage.getItem('fingerprint');
    if (fingerprint === null) {
        return '';
    }
    return fingerprint;
}

export async function getFingerprintAsync() {
    const agent = await fingerprintjs.load();
    const result = await agent.get();
    localStorage.setItem('fingerprint', result.visitorId);
    return result.visitorId;
}

export function getSession() {
    const session = localStorage.getItem('session');
    if (session === null) {
        return '';
    }
    return session;
}

export class ImagePathValueConverter {
  public toView(value) {
    // console.log('images');
    return `images\/${value}`;
  }
}

export class WireframePathValueConverter {
  public toView(value) {
    return `images/wireframe\/${value}`;
  }
}

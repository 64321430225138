export interface IEventData {
  key: string;
  value: any;
  e: Event;
}

/**
 * Fire DOM event on an element
 * @param element The Element which the DOM event will be fired on
 * @param name The Event's name
 * @param data Addition data to attach to an event
 */
export function fireEvent(element: Element, name: string, data: IEventData | any) {
  let event;

  if ((window as any).CustomEvent) {
    event = new CustomEvent(name, {
      bubbles: true,
      detail: data,
    });
  } else {
    event = document.createEvent('CustomEvent');
    event.initCustomEvent(name, true, true, {
      detail: data
    });
  }

  element.dispatchEvent(event);
  return event;
}

export function isDate(s) {
  if (isNaN(s) && !isNaN(Date.parse(s)))
    return true;
  else return false;
}

export function toISOString(date) {
  const dt = new Date(date);
  return dt.getFullYear() + '-' +
    ('0' + (dt.getMonth() + 1)).slice(-2) + '-' +
    ('0' + dt.getDate()).slice(-2);
}

import { customAttribute } from 'aurelia-framework';

abstract class KeyPress {
    value: () => any;
    listener: (e: KeyboardEvent) => void;

    constructor(key: string) {
        this.listener = e => {
            // console.log('KeyPress', e);
            if (e.key === key) {
                return this.value();
            }
        };
    }

    attached() {
        window.addEventListener('keypress', this.listener);
    }

    detached() {
        window.removeEventListener('keypress', this.listener);
    }
}

@customAttribute('key-press-enter')
export class KeyPressEnter extends KeyPress {
    constructor() {
        super('Enter');
    }
}

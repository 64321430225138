import { Container } from 'aurelia-dependency-injection';
import { RenderInstruction, ValidateResult, validateTrigger, ValidationController, ValidationControllerFactory } from 'aurelia-validation';

export class BootstrapFormRenderer {
  public render(instruction: RenderInstruction) {

    for (const { result, elements } of instruction.unrender) {
      for (const element of elements) {
        this.remove(element, result);
      }
    }

    for (const { result, elements } of instruction.render) {
      for (const element of elements) {
        this.add(element, result);
      }
    }
  }

  public add(element: Element, result: ValidateResult) {
    if (result.valid) {
      return;
    }

    let formGroup = element.closest('.mb-3');
    if (!formGroup) {
      formGroup = element.closest('.input-validation');
    }

    if (!formGroup) {
      return;
    }

    let ele: HTMLElement = formGroup.querySelector('input');
    if (ele == null) {
      ele = formGroup.querySelector('select');
    }
    ele.classList.add('is-invalid');

    // add help-block
    const message = document.createElement('div');
    message.className = 'invalid-feedback';
    message.textContent = result.message;
    message.id = `validation-message-${result.id}`;
    ele.parentElement.appendChild(message);
  }

  public remove(element: Element, result: ValidateResult) {
    if (result.valid) {
      return;
    }

    let formGroup = element.closest('.mb-3');
    if (!formGroup) {
      formGroup = element.closest('.input-validation');
    }

    if (!formGroup) {
      return;
    }

    let ele: HTMLElement = formGroup.querySelector('input');
    if (ele == null) {
      ele = formGroup.querySelector('select');
    }
    ele.classList.remove('is-invalid');

    // remove help-block
    const message = ele.parentElement.querySelector(`#validation-message-${result.id}`);
    if (message) {
      ele.parentElement.removeChild(message);
    }
  }
}

export class BootstrapValidationControllerFactory extends ValidationControllerFactory {
  constructor(container: Container) {
    super(container);
  }
  public static get(container: Container) {
    return new BootstrapValidationControllerFactory(container);
  }

  public createForCurrentScope(): ValidationController {
    const ctrl = super.createForCurrentScope();
    ctrl.validateTrigger = validateTrigger.changeOrBlur;
    ctrl.addRenderer(new BootstrapFormRenderer());
    return ctrl;
  }
}

export class BootstrapValidationController extends ValidationController {
  public static get(container: Container) {
    return new BootstrapValidationControllerFactory(container).createForCurrentScope();
  }
}
(BootstrapValidationController as any)['protocol:aurelia:resolver'] = true;

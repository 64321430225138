import { autoinject, customAttribute } from 'aurelia-framework';

@customAttribute('key-down')
export class KeyDown {
    value: () => any;
    listener: (e: KeyboardEvent) => void;

    constructor(key: string) {
        this.listener = e => {
            // console.log('KeyDown', e);
            if (e.key === key) {
                this.value();
            }
        };
    }

    attached() {
        window.addEventListener('keydown', this.listener);
    }

    detached() {
        window.removeEventListener('keydown', this.listener);
    }
}

@customAttribute('key-down-escape')
export class KeyDownEscape extends KeyDown {
    constructor() {
        super('Escape');
    }
}

@customAttribute('key-down-arrow-up')
export class KeyDownArrowUp extends KeyDown {
    constructor() {
        super('ArrowUp');
    }
}

@customAttribute('key-down-arrow-down')
export class KeyDownArrowDown extends KeyDown {
    constructor() {
        super('ArrowDown');
    }
}

@customAttribute('key-down-arrow-left')
export class KeyDownArrowLeft extends KeyDown {
    constructor() {
        super('ArrowLeft');
    }
}

@customAttribute('key-down-arrow-right')
export class KeyDownArrowRight extends KeyDown {
    constructor() {
        super('ArrowRight');
    }
}
import { autoinject, TemplatingEngine } from 'aurelia-framework';

@autoinject()
export class EnhanceCustomAttribute {

    public constructor(private readonly element: Element, private readonly templatingEngine: TemplatingEngine) {
        this.element = (element as HTMLElement);
    }

    public valueChanged(html: string) {
        this.enhance(html);
    }

    private enhance(html: string) {
        this.element.innerHTML = html;
        for (let i = 0; i < this.element.children.length; i++) {
            this.templatingEngine.enhance(this.element.children.item(i));
        }
    }

}

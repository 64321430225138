import { autoinject, bindable } from 'aurelia-framework';

@autoinject()
export class BtnLoadingCustomAttribute {

  @bindable({ primaryProperty: true }) public loading = false;
  @bindable public disabled = false;

  private isAttached = false;
  private innerHTML: string;

  constructor(private readonly element: Element) {
    if (this.element.tagName !== 'BUTTON' && this.element.tagName !== 'A') {
      throw new Error('The btn-loading attribute can only be used in button and anchor elements');
    }
  }

  public attached() {
    this.isAttached = true;
    this.innerHTML = this.element.innerHTML;
    this.setClass();
    this.disabledChanged();
  }

  public loadingChanged() {
    if (this.isAttached) {
      this.setClass();
    }
  }

  public disabledChanged() {
    if (!this.isAttached) {
      return;
    }

    if (this.disabled) {
      if (!this.loading) {
        this.element.classList.add('disabled');
        this.element.setAttribute('disabled', '');
      }
    } else {
      if (!this.loading) {
        this.element.classList.remove('disabled');
        this.element.removeAttribute('disabled');
      }
    }
  }

  public setClass() {
    if (this.loading) {
      this.element.setAttribute('disabled', '');
      this.innerHTML = this.element.innerHTML;
      this.element.innerHTML = '';
      this.element.classList.add('disabled');

      const spinnerTitleElement = document.createElement('span');
      spinnerTitleElement.innerHTML = this.innerHTML;
      this.element.appendChild(spinnerTitleElement);

      const spinnerElement = document.createElement('span');
      spinnerElement.classList.add('spinner-border', 'spinner-border-sm', 'ml-2');
      this.element.appendChild(spinnerElement);
    } else {
      this.element.innerHTML = this.innerHTML;

      if (!this.disabled) {
        this.element.classList.remove('disabled');
        this.element.removeAttribute('disabled');
      }
    }
  }
}

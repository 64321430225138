import { autoinject } from 'aurelia-framework';

@autoinject()
export class DebugCustomAttribute {
  constructor(private readonly element: Element) { }

  public valueChanged(newValue, oldValue) {
    console.log('debug', newValue, oldValue);
  }
}

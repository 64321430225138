import { EditorArgs, EditorValidator } from 'aurelia-slickgrid';

export const CustomFloatValidator: EditorValidator = (value: any, args: EditorArgs) => {

    const params = (args.column.internalColumnEditor && args.column.internalColumnEditor.params) ? args.column.internalColumnEditor.params : undefined;
    const minValue: number = (params && params.hasOwnProperty('minValue')) ? params.minValue : Number.MIN_VALUE;
    const maxValue: number = (params && params.hasOwnProperty('maxValue')) ? params.maxValue : Number.MAX_VALUE;
    const step: number = (params && params.hasOwnProperty('step')) ? params.step : undefined;


    const twoPlacedFloat = parseFloat(value);

    if (value == null || value === undefined || !value.length) {
        // valid
    } else if (Number.isNaN(twoPlacedFloat)) {
        return { valid: false, msg: 'The value should be a decimal value' };
    } else {

        if (twoPlacedFloat.valueOf() < minValue) {
            return { valid: false, msg: `The value should be bigger than ${minValue}` };
        } else if (twoPlacedFloat.valueOf() > maxValue) {
            return { valid: false, msg: `The value should be less than ${maxValue}` };
        } else {
            if (step && (twoPlacedFloat.valueOf() % 1) % step !== 0) {
                return { valid: false, msg: `The value should be in increments of ${step}` };
            }
            return { valid: true, msg: '' };
        }
    }
    return { valid: true, msg: '' };
};

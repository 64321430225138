import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';

@autoinject()
export class NoticeDialog {

    model = {};

    constructor(private readonly controller: DialogController) {
    }

    activate(model) {
        this.model = model;
    }

    deactivate() {

    }
}

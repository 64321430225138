const regexGuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

export function isNull<T extends ([null] extends [T] ? any : never)>(value: T): value is T & null {
    return value === null;
}

export function isUndefined<T extends ([undefined] extends [T] ? any : never)>(value: T): value is T & undefined {
    return value === undefined;
}

export function isDefined<T extends ([undefined] extends [T] ? any : never)>(value: T): value is Exclude<T, undefined> {
    return value !== undefined;
}

export function isNullOrUndefined<T>(value: T | null | undefined): value is null | undefined {
    return value === undefined || value === null;
}

export function not(isIt:boolean): boolean {
    return !isIt;
}

export function to<T>(value: T): T { return value; }

export function ignore(): void { }

export function sleep(ms: number): Promise<void> {
    // tslint:disable-next-line: no-string-based-set-timeout
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function isGuid(str: string) {
    if (str && str.length > 0) {
        if (str[0] === '{') {
            str = str.substring(1, str.length - 1);
        }
        return regexGuid.test(str);
    }
    return false;
}

export function newGuid() {
    return 'AAAAAAAA-AAAA-4AAA-BAAA-AAAAAAAAAAAA'.replace(/[AB]/g,
        (character) => {
            const randomNumber = secureMathRandom();
            // tslint:disable-next-line: no-bitwise
            const num = randomNumber * 16 | 0;
            // tslint:disable-next-line: no-bitwise
            const masked = character === 'A' ? num : (num & 0x3 | 0x8);
            return masked.toString(16);
        });
}

export function emptyGuid() {
    return '00000000-0000-0000-0000-000000000000';
}

export function isEmptyGuid(guid): boolean {
    if (isGuid(guid)) {
        return (guid === '00000000-0000-0000-0000-000000000000');
    }
    return true;
}

export function isInteger(str: any) {
    const n = Math.floor(Number(str));
    return n !== Infinity && Number.isInteger(n) && (n >= -2147483648 && n <= 2147483647);
}

export function isBigInteger(str: any) {
    const n = Math.floor(Number(str));
    return n !== Infinity && Number.isInteger(n) && (n >= -9223372036854775808 && n <= 9223372036854775807);
}

export function secureMathRandom() {
    // Divide a random UInt32 by the maximum value (2^32 -1) to get a result between 0 and 1
    return window.crypto.getRandomValues(new Uint32Array(1))[0] / 4294967295;
}

export function json(body: unknown, replacer ?: (key: string, value: unknown) => unknown): string {
    return JSON.stringify((body !== undefined ? body : {}), replacer);
}

const urlUtils = {
    // https://blog.josequinto.com/2017/03/09/typescript-functions-to-get-current-domain-site-collection-and-site-urls-with-window-location-fallback/#Code
    /*
     * Function to get Current Site Url
     * Samples:
     *      "https://domain.sharepoint.com/sites/intranet/subsite/Pages/Home.aspx"
     */
    getCurrentAbsoluteSiteUrl(): string {
        if (window
            && 'location' in window
            && 'protocol' in window.location
            && 'pathname' in window.location
            && 'host' in window.location) {
            return window.location.protocol + '//' + window.location.host + window.location.pathname;
        }
        return null;
    },

    /*
     * Function to get Current Site Url
     * Samples:
     *      "/sites/intranet"
     */
    getWebServerRelativeUrl(): string {
        if (window
            && 'location' in window
            && 'pathname' in window.location) {
            return window.location.pathname.replace(/\/$/, '');
        }
        return null;
    },

    /*
     * Function to get Current Domain Url
     * Samples:
     *      "https://domain.sharepoint.com"
     */
    getAbsoluteDomainUrl(): string {
        if (window
            && 'location' in window
            && 'protocol' in window.location
            && 'host' in window.location) {
            return window.location.protocol + '//' + window.location.host;
        }
        return null;
    }
};

export { urlUtils };